<template>
  <el-form
    :model="addForm"
    :rules="rules"
    ref="addForm"
    label-width="82px"
    label-position="left"
    class="demo-form dialog-form"
  >
    <!-- 用户姓名： -->
    <el-form-item label="用户姓名：" prop="realname">
      <el-input v-model.trim="addForm.realname" placeholder="请输入用户姓名" :disabled="userId!==''"></el-input>
    </el-form-item>
    <!-- 用户名： -->
    <el-form-item label="用户名：" prop="username">
      <el-input v-model.trim="addForm.username" placeholder="请输入用户名" :disabled="userId!==''"></el-input>
    </el-form-item>
    <!-- 性别 -->
    <el-form-item label="性别：" prop="gender">
      <el-select v-model="addForm.gender" placeholder="请选择性别">
        <el-option label="男" :value="1"></el-option>
        <el-option label="女" :value="0"></el-option>
      </el-select>
    </el-form-item>
    <!-- 密码： -->
    <!-- <el-form-item label="密码：" prop="password" v-if="!userId">
      <el-input v-model.trim="addForm.password" type="password" placeholder="请输入密码"></el-input>
    </el-form-item>-->
    <!-- 联系方式： -->
    <el-form-item label="联系方式：" prop="phone">
      <el-input v-model.trim="addForm.phone" placeholder="请输入手机号码"></el-input>
    </el-form-item>
    <!-- 邮箱： -->
    <el-form-item label="邮箱：" prop="email">
      <el-input v-model="addForm.email"></el-input>
    </el-form-item>
    <!-- 所属部门： -->
    <el-form-item label="所属部门：" prop="deptId">
      <companySelect :value="companyId" :valueName="companyName" @getValue="getGroupId" />
    </el-form-item>
    <!-- 角色： -->
    <el-form-item label="角色：" prop="role">
      <el-select v-model="addForm.role" multiple collapse-tags placeholder="请选择所属角色">
        <el-option
          v-for="role in roleList"
          :label="role.name"
          :value="role.id"
          :key="role.id"
          :disabled="role.status!==0"
        ></el-option>
      </el-select>
    </el-form-item>
    <!--  用户描述：-->
    <el-form-item label="用户描述：">
      <el-input v-model.trim="addForm.description" type="textarea"></el-input>
    </el-form-item>
  </el-form>
</template>
<script>
import {
  checkPhone,
  checkUsername,
  checkPassword,
  checkName,
  checkEmailNumber
} from "@/common/utils/index.js";
import {
  getUserInfoById,
  insertUser,
  updateUser,
  getRoleLabelList
} from "@/api/lib/api.js";
import companySelect from "@/components/treeSelect/companySelect.vue";
export default {
  components: {
    companySelect
  },
  props: {
    userId: {
      type: String
    },
  },
  data () {
    return {
      companyId: null,
      companyName: '',
      isSameUser: false,
      addForm: {
        avatar: "default",
        deptId: null,
        description: "",
        email: "",
        gender: 0,
        phone: "",
        realname: "",
        role: [],
        status: 0,
        username: ""
      },
      rules: {
        realname: [
          {
            required: true,
            message: "请输入用户姓名",
            trigger: "blur"
          },
          { validator: checkName, trigger: "blur" }
        ],
        phone: [
          {
            required: true,
            message: "请输入手机号码",
            trigger: "blur"
          },
          { validator: checkPhone, trigger: "blur" }
        ],
        username: [
          {
            required: true,
            message: "请输入用户名",
            trigger: "blur"
          },
          { validator: checkUsername, trigger: "blur" }
        ],
        gender: [{ required: true, message: "请选择性别", trigger: "blur" }],

        /* password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            min: 6,
            max: 18,
            message: "密码为6-18位数字或字母",
            trigger: "blur"
          },
          { validator: checkPassword, trigger: "blur" }
        ], */
        deptId: [
          {
            required: true,
            message: "请选择所属部门",
            trigger: "blur"
          }
        ],
        email: [
          {
            required: true,
            message: "请输入邮箱地址",
            trigger: "blur"
          },
          { validator: checkEmailNumber, trigger: "blur" }
        ],
        userGroupId: [
          { required: true, message: "请选择用户组", trigger: "blur" }
        ],
        role: [
          {
            required: true,
            message: "请选择所属角色",
            trigger: "blur"
          }
        ]
      },
      roleList: []
    };
  },
  methods: {
    /**获取企业id */
    getGroupId (v) {
      this.addForm.deptId = v;
    },
    // 新增/保存用户
    onSave () {
      this.$refs.addForm.validate(valid => {
        if (valid) {
          let data = {
            realname: this.addForm.realname,
            username: this.addForm.username,
            phone: this.addForm.phone,
            deptId: this.addForm.deptId,
            email: this.addForm.email,
            role: this.addForm.role,
            description: this.addForm.description,
            avatar: "default",
            gender: this.addForm.gender
          };
          if (this.userId === "") {
            // data = {
            //   ...data,
            //   status: 0
            // };
            insertUser(data).then(res => {
              if (res.code === 0) {
                this.$message.success("新增成功");
              } else {
                this.$message.error("新增失败");
              }
              this.$emit("onDialogClose", res.code === 0);
            });
          } else {
            data = {
              ...data,
              id: this.addForm.id,
              // status: this.addForm.status
            };
            updateUser(data).then(res => {
              if (res.code === 0) {
                this.$message.success("修改成功");
              } else {
                this.$message.error("修改失败");
              }
              this.$emit("onDialogClose", res.code === 0);
            });
          }
        }
      });
    },
    /**获取角色列表 */
    getRoleLabelList () {
      getRoleLabelList().then(res => {
        if (res.code === 0) {
          this.roleList = res.data;
        }
      });
    }
  },
  created () {
    this.getRoleLabelList();
    let role = []
    if (this.userId) {
      getUserInfoById(this.userId).then(res => {
        if (res.code === 0) {
          if (localStorage.getItem("username") === res.data.username) {
            this.isSameUser = true; //当前用户修改自己的数据
          }
          this.addForm = { ...res.data };
          delete this.addForm.department
          delete this.addForm.createTime
          delete this.addForm.lastLoginTime
          delete this.addForm.registerTime
          delete this.addForm.updateTime
          delete this.addForm.roleList
          this.companyId = res.data.department.id
          this.companyName = res.data.department.name
          res.data.roleList.forEach(element => {
            role.push(String(element.id))
          });
          this.addForm.role = role
        }
      });
    }
  },
  mounted () { }
};
</script>